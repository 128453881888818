export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
  lightGreen: "#13ac7e",
  green: "#34d7a6",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.lightGreen}, ${
  COLORS.green
})`

export const BORDER_RADIUS = "10px"
