/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"

import Footer from "./footer"
import "../styles/default.css"

const Layout = ({ children }) => {

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      logo: file(absolutePath: { regex: "/unpublisher-logo-light.png/" }) {
        childImageSharp {
          fixed(width: 240, quality: 95) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      site {
          siteMetadata {
            title
          }
        }
    }
  `)

  let header
  const logo = data?.logo?.childImageSharp?.fixed;

  header = (
      <div className="header-link-home-inner-flex">
        <Link className="header-link-home" to="/">
          <Image
            loading={"eager"}
            fixed={logo}
            alt={`Unpublisher.co logo`}
          />
        </Link>
        {/*<Link className="header-link-tool" to="/article-2-tweets">*/}
        {/*  Generate Tweets*/}
        {/*</Link>*/}
      </div>
  )

  return (
    <div className="global-wrapper">
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
